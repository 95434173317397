@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#button-primary {
  background-color: #135EF2;
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  border: none;
  padding: 18px;
  padding-left: 40px;
  padding-right: 40px;
}

#button-primary-transparent {
  color: #135EF2;
  background: none;
  border: 1px solid #135EF2;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
}

#menu-toggler {
  color: white;
  border: none;
}

a {
  font-family: "Montserrat"
}

.navbar-toggler-icon{
  background-image: url('./media/toggler.svg')!important;
}

.accordion-button{
  background-color: #23354E !important;
  color: #E3E3E3 !important;
  margin-bottom: 30px;
  border-radius: 6px !important;
}

.accordion-button .collapsed{
  border-radius: 6px !important;
}

button.accordion-button.collapsed{
  
}